.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #014140;
    z-index: 9999;
  }
  
  .spinner {
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
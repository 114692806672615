.rosca-explain-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #F7F9F7;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 100px 0;
    padding: 20px;
    width: 80%;
  }
  
  .circlee {
    position: relative;
    min-width: 400px;
    width: 400px;
    height: 400px;
    border: 10px solid white;
    border-radius: 45%;
    margin-right: 100px !important;
    margin: 20px;
  }
  
  .circlee::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 2px solid currentColor;
    border-radius: 45%;
  }
  
  .icon {
    position: absolute;
    top: 16%;
    left: 30%;
    width: 340px;
  }
  
  .text-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 10%;
    width: 40%;
  }
  
  .text-content h2 {
    margin: 0;
    font-size: 1.6rem;
    color: #014140;
  }
  
  .text-content p {
    margin: 50px 0 0;
    font-size: 1.3rem;
    color: #014140;
  }
  
  /* Mobile styles */
  @media (max-width: 1350px) {
    .section {
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 90%;
      min-height: 35rem;
    }
  
    .circlee {
      min-width: 200px;
      width: 200px;
      height: 200px;
      margin: 20px 0;
    }
    .text-content {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-left: 0%;
      width: 100%;
    }
    .icon {
      top: 5%;
      right: 5%;
      width: 200px;
    }

    .text-content h2 {
      font-size: 1.2rem;
    }
  
    .text-content p {
      font-size: 1.1rem;
    }
  }
  
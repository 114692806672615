@import './reset.css';

.App {
  width: 100%;
  height: 100%;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box;
  scroll-behavior: smooth; /* Add smooth scrolling behavior */
}

#root {
  width: 100%;
  height: 100%;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

nav ul li {
  margin: 0 10px;
}

section {
  margin: 20px 0;
  padding: 20px;
}

/* Mobile styles */
@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 10px 0;
  }

  section {
    padding: 10px;
  }
}

/* Desktop styles */
@media (min-width: 769px) {
  .App {
    margin: 0 auto;
  }

  section {
    padding: 40px;
  }
}

.contact-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #014140; /* New background color */
  }
  
  .contact-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center the content */
    padding: 20px;
    margin-top: 14rem;
  }
  
  h2 {
    color: #ffffff; /* White color for the heading */
    margin-bottom: 20px;
  }
  
  .contact-form {
    width: 100%;
    max-width: 500px;
    background-color: #ffffff; /* White background for form */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #00796b; /* Primary color */
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  input:focus,
  textarea:focus {
    border-color: #00796b; /* Primary color */
    outline: none;
  }
  
  textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #00796b; /* Primary color */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #005f56; /* Darker shade of primary color */
  }
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #00796b; /* Primary color */
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  input:focus,
  textarea:focus {
    border-color: #00796b; /* Primary color */
    outline: none;
  }
  
  textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #00796b; /* Primary color */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #005f56; /* Darker shade of primary color */
  }
  
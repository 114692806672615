.section-container-hrw {
  width: 100%;
  max-width: 800px; /* Increase max-width for better view */
  padding: 20px;
  margin: 5rem auto; /* Center the container */
  border-radius: 10px;
  position: relative; /* Ensure positioning for circles */
}

.section-container-hrw h2 {
  margin-bottom: 7vh;
  text-align: center;
  font-size: 2vh;
  color: #014140;
  position: relative;
}

.section-container-hrw h2 span {
  display: inline-block;
  background-color: #ebebeb;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #3f3f3f;
}


.video-container {
  border: 9px solid #42f7a8;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000; /* Black background for video box */
  border-radius: 30px;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  border-radius: 10px;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.video-container:hover .video-overlay {
  opacity: 1; /* Show overlay on hover */
}

.overlay-content h3 {
  margin: 0;
  font-size: 2rem;
}

.overlay-content p {
  margin: 0.5rem 0 0;
  font-size: 1.2rem;
}

.circle-pattern {
  position: absolute;
  border-radius: 50%;
  /* transition: all 0.1s ease; */
  z-index: -1; /* Ensure circles are behind the content */
}

.pattern1 {
  border: #E5EEE6 3rem solid;
  width: 30rem;
  height: 30rem;
  top: -15%;
  left: -50vw;
}

.pattern2 {
  border: #E5EEE6 4rem solid;
  width: 15rem;
  height: 15rem;
  top: 100%;
  left: 155%;
}

.circle-pattern.expand {
  width: 150px;
  height: 150px;
}
@media (max-width: 1350px) {
  .section-container-hrw {
    margin: 12rem auto; /* Center the container */
  }
}
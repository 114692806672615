/* Remove blue click effect */
.faq-question-header,
.faq-subject {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.section-container {
  padding: 20px;
  margin: 20px 0; /* Ensure margin between sections */
  background-color: #f9f9f9;
  border-radius: 10px;
}

.section-container h2 {
  margin-bottom: 10px;
}

.section-container p {
  margin-bottom: 15px;
}

.faq-container {
  max-width: 80%;
  min-width: 80%;
  padding: 20px;
  /* background-color: #f9f9f9; */
  border-radius: 10px;
  margin-top: 10rem;
}

.faq-container h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #014140;
}

.faq-content {
  display: flex;
  min-height: 30rem;
}

.faq-subjects {
  flex: 0.5 1;
  display: flex;
  flex-direction: column;
}

.faq-subject {
  padding: 10px;
  /* margin-bottom: 10px; */
  color: #004141;
  /* border-radius: 5px; */
  cursor: pointer;
  text-align: left;
}

.faq-subject:last-child {
  border-bottom: none; /* Remove border-bottom for the last subject */
}

.faq-subject.active {
  /* background-color: #006666; */
  color: #000808;
  font-weight: bold;
}

.faq-questions {
  flex: 2;
}

.faq-question {
  margin-bottom: 10px;
}

.faq-question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #F6FFFF;
  color: #000000;
  border-radius: 5px;
  cursor: pointer;
  border: 0.5px solid rgb(0, 65, 65, 25%);
  transition: all 0.3s ease;
}

.faq-question-header.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.faq-answer {
  padding: 10px;
  background-color: #e0f7fa;
  color: #004141;
  border-radius: 0 0 5px 5px;
  margin-top: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}

.arrow {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  transform-origin: 50% 50%; /* Center the transform origin */
}

.arrow.open {
  transform: rotate(180deg);
}

.faq-container h2 {
  margin-bottom: 7vh;
  text-align: center;
  font-size: 2vh;
  color: #014140;
  position: relative;
}

.faq-container h2 span {
  display: inline-block;
  background-color: #ebebeb;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #3f3f3f;
}
/* Mobile styles */
@media (max-width: 850px) {
  .faq-subject {
    border-bottom: 1px solid rgb(204 204 204 / 42%);
  }
  .faq-content {
    flex-direction: column;
    min-height: 45rem;
  }
  .faq-subjects {
    margin-bottom: 30px;
    margin-right: 0;
    flex: 0 1;
  }
  .faq-container {
    max-width: 100%;
  }
  .faq-container h2 {
    text-align: left;
    margin-bottom: 1.5rem;
  }
  .faq-container h2:first-child {
    text-align: center;
    margin-bottom: 1.5rem;
  }
}

.benefits-container {
    text-align: center;
    padding: 50px 20px;
    background-color: #f7f9f7;
    border-radius: 40px;
    width: 90%;
        margin-top: 10rem;
  }
  
  .benefits-container h2 {
    color: #014140;;
    margin-bottom: 40px;
    font-size: 2rem;
  }
  
  .benefits-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .benefit-box {
    background-color: #42f7a8;
    padding: 17px;
    border-radius: 15px;
    width: 180px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .benefit-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .benefit-icon {
    max-width: 100px;
    margin-bottom: 15px;
  }
  
  .benefit-box p {
    color: #014140;
    font-size: 1rem;
    font-weight: bold;
  }
  
  /* Mobile Zig-Zag Layout */
  @media (max-width: 600px) {
    .benefits-grid {
      flex-direction: column;
      align-items: center;
    }
  
    .benefit-box:nth-child(odd) {
      transform: translateX(-15%);
    }
  
    .benefit-box:nth-child(even) {
      transform: translateX(15%);
    }
  
    .benefit-box {
      width: 80%;
      margin-bottom: 20px;
    }
  }
  
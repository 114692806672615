.footer {
  background-color: #001818;
  color: #B8ECFF;
  padding: 40px 20px;
  text-align: center;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  margin-top: 20rem;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin: 10px;
  align-content: center;
}

.footer-section.text-section {
  flex: 2;
  max-width: 33%;
  text-align: justify;
}

.footer-section h3 {
  margin-bottom: 10px;
}

.footer-section p,
.footer-section a {
  color: white;
  margin: 5px 0;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}

.footer-logo {
  max-width: 150px;
  margin-bottom: 10rem;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social-icons a {
  color: #ffffff;
  margin: 0 10px;
  font-size: 1.5rem;
}

.social-icons a:hover {
  color: #42f7a8;
}

.logo-social-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.links-section ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.links-section ul li {
  margin-bottom: 5px;
}

.footer-bottom {
  padding-top: 20px;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 20px;
  width: 100%;
}

.footer-bottom a {
  margin: 5px 0;
  text-decoration: none;
  color: #B8ECFF;
}

/* Styling for the legal text */
.legal-text {
  font-size: 0.75rem; /* Smaller font size */
  color: rgba(184, 236, 255, 0.7); /* Slightly darkened color */
  line-height: 1.5;
  margin-top: 10px;
}

/* Mobile styles */
@media (max-width: 600px) {
  .footer-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-logo {
    margin: 0;
  }

  .links-section ul {
    text-align: left;
    display: block;
    gap: 0; /* Remove gap for mobile view */
    padding-left: 11px;
  }

  .social-icons {
    justify-content: flex-start;
    margin-top: 20px;
  }

  .footer-bottom {
    text-align: center;
    margin-top: 10px;
  }

  .footer-section.text-section {
    max-width: 100%; /* Make sure the text takes up the full width on mobile */
  }
}

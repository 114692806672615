/* Remove the light blue effect on touch devices */
.step:focus,
.step:active,
.step-header:focus,
.step-header:active {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
.registratie-container {
  background-color: #004141;
  color: #B8ECFF;
  padding: 20px;
  border-radius: 20px;
  min-width: 96%;
  min-height: 67rem;
  margin: 20px;
  margin-top: 10rem;
}

.RPh1 {
  text-align: left;
  margin-bottom: 3rem;
  margin-top: 5rem;
  font-size: 3rem;
  max-width: 60%;
  padding: 10px 15px;
  margin-left: 2.5rem;
}

.RPp {
  font-size: 2.3rem;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.iDINmockup-image {
  max-width: 30%;
  margin: 20px;
  border-radius: 20px;
}

.steps {
  flex: 1;
  min-width: 50%;
}

.step {
  border-radius: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.step-header {
  padding: 10px 15px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-left: 2.5rem;
  margin-top: 1rem;
}

.step-number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-number {
  background-color: #B8ECFF;
  color: #004141;
  min-width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 32px;
  font-size: 2rem;
  font-weight: bold;
}

.step-arrow {
  color: #B8ECFF;
  font-size: 3.5rem;
  position: absolute;
  margin-left: 4.8rem;
  margin-bottom: 4rem;
}

.step-content {
  overflow: hidden;
  padding: 10px 15px;
  font-size: 1.5rem;
  margin-left: 9rem;
  will-change: transform, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
}

/* Mobile styles */
@media (max-width: 850px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .iDINmockup-image {
    max-width: 60%;
    margin: 0 0 20px 0;
  }

  .steps {
    width: 100%;
  }

  .RPh1 {
    max-width: 100%;
    font-size: 1.7rem;
    margin-left: 0rem;
  }

  .RPp {
    font-size: 1.3rem;
  }

  .step-header {
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .step-number {
    min-width: 40px;
    height: 40px;
    font-size: 1.5rem;
  }

  .step-arrow {
    font-size: 3rem;
    margin-left: 1.5rem;
  }

  .step-content {
    margin-left: 4.6rem;
    font-size: 1rem;
  }

  .registratie-container {
    min-height: 80rem;
  }
}

@media (max-width: 500px) {
  .iDINmockup-image {
    max-width: 100%;
    margin: 0 0 20px 0;
  }

  .RPh1 {
    max-width: 100%;
  }
  
  .step-header {
      margin-top: 0rem;
      padding: 10px 0px;
  }
  .step-number {
      margin-right: 15px;
    }
  .step-content {
    margin-left: 2.6rem;
    font-size: 1rem;
  }
  .step-arrow {
    margin-left: 0.5rem;
}
}

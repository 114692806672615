/* Existing styles */
.home-container {
  height: 58rem; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  display: flex;
  flex-direction: column; /* Change to column for vertical stacking */
  justify-content: flex-start; /* Align items at the start */
  align-items: center;
  background-image: url('../images/Background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  position: relative; /* Ensure child elements are positioned relative to this container */
}

.home-content {
  color: white;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px; /* Add margin to space out sections */
  margin: 10rem 0 0 0;
}

.line h1 {
  font-size: 4rem; /* Adjust size as needed */
}

.dynamic-container {
  rotate: -6deg;
  min-height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dynamic-name {
  background-color: rgb(76 246 169 / 50%); /* Light green background for dynamic name */
  padding: 0 10px; /* Add some padding for better appearance */
  display: inline-block;
  border-right: 2px solid white; /* Cursor effect */
  white-space: nowrap;
  overflow: hidden;
  border-radius: 10px 0px 0px 10px;
}

.mockup-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12rem;
}

.circle {
  position: absolute;
  bottom: 10rem;
  border: 3rem solid #42f7a8;
  border-radius: 50%;
  width: 0;
  height: 0;
  animation: growCircle 1s ease-out forwards;
  z-index: 0;
}

@keyframes growCircle {
  from {
    width: 0;
    height: 0;
  }
  to {
    width: 25rem; /* Adjust size as needed */
    height: 25rem; /* Adjust size as needed */
  }
}

.mockup-image {
  margin-top: 20px; /* Space between text and image */
  width: 50rem; /* Adjust size as needed */
  height: auto;
  animation: slideUp 1s cubic-bezier(0, 0, 0, 0.94); /* Add the slide-up animation */
  opacity: 0; /* Ensure the image is initially hidden */
  animation-fill-mode: forwards; /* Retain the final state of the animation */
}

@keyframes slideUp {
  from {
    transform: translateY(100vh); /* Start from below the viewport */
    opacity: 0;
  }
  to {
    transform: translateY(0); /* End at the final position */
    opacity: 1;
  }
}

.store-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Space between mockup image and store buttons */
}

.store-image {
  width: 12rem; /* Adjust size as needed */
  margin: 0 10px;
  cursor: pointer; /* Change cursor to pointer */
}

.menu-bg {
  display: none;
}

.download-images {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.download-desk-image {
  display: none;
  max-width: 70%;
}

.download-mobile-image {
  display: none;
  max-width: 80%;
}

/* Mobile styles */
@media (max-width: 850px) {
  .home-container {
    background-image: none;
    background-color: #014140;
  }

  .home-content {
    padding: 10px;
  }

  .mockup-wrapper {
    margin-bottom: 30vh;
    margin-top: 10vh;
  }

  .circle {
    position: absolute;
    top: -8vh;
    border: 9vw solid #42f7a8;
    border-radius: 50%;
    width: 0;
    height: 0;
    animation: growCircle 1s ease-out forwards;
    z-index: 0;
  }

  @keyframes growCircle {
    from {
      width: 0;
      height: 0;
    }
    to {
      width: 90vw; /* Adjust size as needed */
      height: 90vw; /* Adjust size as needed */
    }
  }

  .mockup-image {
    width: 100%; /* Make image responsive */
    margin: 0;
  }

  .line h1 {
    font-size: 2rem; /* Adjust size as needed */
  }

  .store-buttons {
    flex-direction: column;
    align-items: center; /* Center the store buttons */
    margin-top: 10px; /* Space between mockup image and store buttons */
  }

  .store-image {
    width: 50%; /* Adjust size for mobile view */
    margin: 10px 0;
  }
}

@media (max-width: 700px) {
  .download-desk-image {
    display: none; /* Hide desktop image on mobile */
  }

  .download-mobile-image {
    display: block; /* Show mobile image */
  }
}

@media (min-width: 701px) {
  .download-desk-image {
    display: block; /* Show desktop image */
  }

  .download-mobile-image {
    display: none; /* Hide mobile image */
  }
}

/* Updated Navbar styles */

.HoverLink {
  background: #42f7a8;
  padding: 6px;
  border-radius: 16px;
  transition: background-color 0.3s ease; /* Ensure smooth transition */
}

.HoverLink:hover {
  background-color: rgb(44, 44, 44); /* Adjust the color as needed */
}

.HoverLink a:hover {
  color: white;
}

.logo {
  width: 150px; /* Adjust the logo size as needed */
  height: auto;
}

.nav-links {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links a {
  color: white; /* Ensure the text color is white */
  text-decoration: none;
}

.nav-links a:hover {
  color: rgba(255, 255, 255, 0.623); /* Ensure the text color is white */
  text-decoration: none;
}

/* Mobile styles */
@media (max-width: 850px) {
  nav {
    justify-content: space-between; /* Space between logo and hamburger menu */
    background: transparent; /* Ensure the background is transparent by default */
    padding: 30px 20px; /* Increase padding for top and right */
  }

  .hamburger {
    display: block; /* Display the hamburger menu */
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 90vw; /* 90% width of the viewport */
    height: 100vh; /* Full viewport height */
    text-align: center;
    background: white; /* Set background to white */
    position: fixed; /* Fixed position to cover the whole screen */
    top: 0; /* Start from the top of the viewport */
    left: 0;
    padding-top: 100px; /* Space between top and first menu item */
    z-index: 15; /* Ensure it stays above other content */
    opacity: 1; /* No transparency */
    transform: translateX(-100%); /* Start off-screen */
    transition: transform 0.3s ease; /* Smooth sliding transition */
  }

  .nav-links.open {
    transform: translateX(0); /* Slide in */
  }

  .nav-links li {
    margin: 20px 0;
  }

  .nav-links a {
    color: black; /* Change font color to black */
  }

  /* Darken background when menu is open */
  .menu-bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
    z-index: 14; /* Just below the menu */
  }

  .menu-bg.open {
    display: block;
  }
}

@media (max-width: 400px) {
  nav {
    padding: 30px 10px; /* Increase padding for top and right */
  }
}

/* Green background for hamburger lines when open */
.hamburger.open .line {
  background-color: #42f7a8;
}

/* Prevent body scroll when menu is open */
body.menu-open {
  overflow: hidden;
}
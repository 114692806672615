/* Remove the light blue effect on touch devices */
nav:focus,
nav:active,
.hamburger:focus,
.hamburger:active,
.nav-links a:focus,
.nav-links a:active {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
nav {
  width: 100%;
  padding: 30px 70px; /* Increase padding for top and right */
  box-sizing: border-box;
  display: flex;
  justify-content: space-between; /* Space between logo and nav links */
  align-items: center; /* Vertically center items */
  background: transparent; /* Make the background transparent */
  position: absolute; /* Position it absolutely within the parent */
  top: 0;
  left: 0;
  z-index: 10; /* Ensure it stays above other content */
}

.HoverLink {
  background: #42f7a8;
  padding: 6px;
  border-radius: 16px;
  transition: background-color 0.3s ease; /* Ensure smooth transition */
}

.HoverLink:hover {
  background-color: rgb(44, 44, 44); /* Adjust the color as needed */
}
.HoverLink a:hover {
  color: white;
}

.logo {
  width: 150px; /* Adjust the logo size as needed */
  height: auto;
}

.hamburger {
  display: none;
  width: 30px;
  height: 15px;
  position: relative;
  cursor: pointer;
  z-index: 20;
}

.hamburger .line {
  border-radius: 15px;
  width: 100%;
  height: 4px;
  background-color: white;
  position: absolute;
  left: 0;
  transition: all 0.3s ease;
}

.hamburger .line:first-child {
  top: 0;
}

.hamburger .line:last-child {
  bottom: 0;
}

.hamburger.open .line:first-child {
  transform: rotate(45deg);
}

.hamburger.open .line:last-child {
  transform: rotate(-45deg);
  bottom: 70%;
}

.nav-links {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links a {
  color: white; /* Ensure the text color is white */
  text-decoration: none;
}

.nav-links a:hover {
  color: rgba(255, 255, 255, 0.623); /* Ensure the text color is white */
  text-decoration: none;
}

/* Mobile styles */
@media (max-width: 850px) {
  nav {
    justify-content: space-between; /* Space between logo and hamburger menu */
    background: transparent; /* Ensure the background is transparent by default */
    padding: 30px 20px; /* Increase padding for top and right */
  }

  .hamburger {
    display: block; /* Display the hamburger menu */
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    background: white; /* Set background to white */
    position: absolute;
    left: 0;
    padding-top: 10px; /* Space between navbar and menu */
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .nav-links a {
    color: black; /* Change font color to black */
  }
}
@media (max-width: 400px) {
  nav {
    padding: 30px 10px; /* Increase padding for top and right */
  }
}